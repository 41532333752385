/* eslint-env jquery */

import { JqueryMmenu } from 'jquery.mmenu'; // eslint-disable-line no-unused-vars

export class JqueryMobileMenu {
    /**
     * Initialize a jquery.mmenu menu.
     *
     * @param {string} menuId The HTML id of the panel.
     * @param {object} options Options for jquery.mmenu.
     * @param {object} configuration Configuration for jquery.mmenu.
     */
    constructor(menuId, options, configuration) {
        this.menuId = menuId;

        // Find the menu element.
        const node = document.querySelector(this.menuId);

        // Make sure the menu exists.
        if (node instanceof HTMLElement === false) {
            return;
        }

        if (!jQuery().mmenu) {
            return;
        }

        this.menu = jQuery(menuId).mmenu(options, configuration);

        // Workaround for flash of menu.
        setTimeout(() => {
            node.classList.remove('mm--loading');
        }, 200);
    }
}
