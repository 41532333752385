/* eslint-env jquery */

import 'bootstrap.native'; // eslint-disable-line import/no-unassigned-import

//import { M6FormValidation } from 'M6/form_validation/jquery.form_validation'; // eslint-disable-line no-unused-vars, import/no-unresolved, import/extensions

import { JqueryMobileMenu } from './jquery-mobile-menu';

export class App {
    /**
     * Initialize the app on dom content loaded.
     */
    // eslint-disable-next-line max-lines-per-function
    dom() {
        // M6 Form Validation
        // ---------------------------------------------------------------------
        jQuery(($) => {
            if (!$().formValidation) {
                return;
            }

            $('.arForm:not(.arFormNoValidation)').formValidation({
                errorPosition: 'right',
            });
        });

        // Mobile Menu
        // ---------------------------------------------------------------------
        document.addEventListener('DOMContentLoaded', () => {
            // eslint-disable-next-line no-unused-vars
            const mobileMenu = new JqueryMobileMenu(
                '#mobile-menu-panel',
                {
                    navbar: {},
                    // Options
                    extensions: [
                        // Make the menu open on the right instead of left.
                        'position-right',
                    ],
                },
                {
                    // Configuration
                }
            );
        });

        // Stop YouTube video playing when modal is closed
        // ---------------------------------------------------------------------
        document.addEventListener('DOMContentLoaded', () => {
            const videoModal = document.getElementById('videoModal');
            if (videoModal instanceof HTMLElement === true) {
                videoModal.addEventListener(
                    'hidden.bs.modal',
                    (e) => {
                        const videoModalIframe = document.querySelector(
                            '#videoModal iframe'
                        );

                        if (videoModalIframe !== null) {
                            videoModalIframe.setAttribute(
                                'src',
                                videoModalIframe.getAttribute('src')
                            );
                        }
                    },
                    false
                );
            }
        });

        //Countdown Flexible Section Timer
        if (jQuery('#countdown-timer').length > 0) {

            var correctDate = document.getElementById("hidden-date").innerHTML;

            // Set the date we're counting down to
            var countDownDate = new Date(correctDate).getTime();

            // Update the count down every 1 second
            var x = setInterval(function() {

            // Get today's date and time
            var now = new Date().getTime();

            // Find the distance between now and the count down date
            var distance = countDownDate - now;

            // Time calculations for days, hours, minutes and seconds
            var days = Math.floor(distance / (1000 * 60 * 60 * 24));
            var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            var seconds = Math.floor((distance % (1000 * 60)) / 1000);

            // Output the result in an element with id="demo"
            document.getElementById("countdown-timer").innerHTML = days + "<sup>d</sup> " + hours + "<sup>h</sup> "
            + minutes + "<sup>m</sup> " + seconds + "<sup>s</sup> ";

            // If the count down is over, write some text
            if (distance < 0) {
                clearInterval(x);
                document.getElementById("countdown-timer").innerHTML = "Event Passed";
            }
            }, 1000);
        }

        //Line Title Section Animation
        if ( jQuery(".line_title").length > 0 ){

            function isScrolledIntoView(elem) {
                var docViewTop = jQuery(window).scrollTop();
                var docViewBottom = docViewTop + jQuery(window).height();

                var elemTop = jQuery(elem).offset().top;
                var elemBottom = elemTop + jQuery(elem).height();

                return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
            }

            // Function activated when scrolling
            jQuery(window).scroll(function() {
                // Check if the element is visible
                jQuery(document).ready(function() {
                    if( isScrolledIntoView(".line_title .end-bar") ) {

                        jQuery(".line_title .bar").addClass('active');

                    }
                });
            });



        }
    }
}
